import React from 'react';

const NavSalesforce = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99905 16C9.99905 14.0222 10.5855 12.0888 11.6844 10.4443C12.7832 8.79981 14.345 7.51809 16.1722 6.76121C17.9995 6.00433 20.0101 5.8063 21.95 6.19215C23.8898 6.578 25.6716 7.53041 27.0701 8.92894C28.4686 10.3275 29.4211 12.1093 29.8069 14.0491C30.1928 15.9889 29.9947 17.9996 29.2378 19.8268C28.481 21.6541 27.1992 23.2159 25.5548 24.3147C23.9103 25.4135 21.9769 26 19.9991 26H8.99905C8.00635 25.999 7.0252 25.7869 6.12074 25.3778C5.21628 24.9686 4.40918 24.3718 3.75303 23.6269C3.09687 22.8819 2.60666 22.006 2.31494 21.0571C2.02321 20.1082 1.93665 19.1081 2.06098 18.1233C2.18532 17.1384 2.51771 16.1912 3.03611 15.3446C3.5545 14.498 4.24703 13.7713 5.06773 13.2129C5.88844 12.6544 6.81854 12.2768 7.79631 12.1053C8.77408 11.9338 9.77716 11.9722 10.739 12.2179"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavSalesforce;
